module.exports = {
  language: 'РУС',
  logo: {
    text: 'Нейросети <br/>Ашманова',
  },
  nav: [
    { title: 'Главная', href: '/' },
    { title: 'Продукты', href: '/products/' },
    { title: 'Консалтинг', href: '/consulting/' },
    /*{ title: 'Демо', href: '/demos/' },*/
    { title: 'PuzzleLib', href: '/puzzlelib/' },
    { title: 'Face Radar', href: '/faceradar/' },
    { title: 'Наши партнеры', href: '/partners/' },
    { title: 'Блог', href: '/blog/' },
    { title: 'Контакты', href: '/contacts/' },
  ],
  vacanciesLink: 'Вакансии',
  contactBtn: 'Напишите нам',
  frontpage: {
    title: 'Нейросети Ашманова – технологии искусственного интеллекта и машинного обучения',
    meta: {
      description: 'Занимаемся разработкой систем распознавания и синтеза изображений, голоса, экспертных систем; систем искусственного интеллекта и программного обеспечения для любого вида бизнеса. Проводим консультации по применению нейросетей и машинного обучение в вашей деятельности.',
    },
    banner: {
      h1: ' Приложения и сервисы на нейронных сетях',
      config: [
        {
          text:
            'Разрабатываем продукты на нейронных сетях <br />и машинном обучении.<br /> Консультируем по применению машинного обучения.',
          btn: 'Подробнее',
          href: '/consulting/',
        },
        {
          text:
            'Хотите понять, чем машинное обучение может быть полезно вам? Предлагаем встретиться для бесплатной консультации.',
          btn: 'Отправить заявку',
          href: '/#contact',
        },
        {
          text: 'Библиотека PuzzleLib: мы разработали самую <br />быструю библиотеку для построения нейронных сетей.',
          btn: 'Подробнее',
          href: '/puzzlelib/',
        },
      ],
    },
    about: {
      heading: 'О компании',
      text:
        'Компания “Нейросети Ашманова” основана Станиславом Ашмановым и Игорем Модяевым в 2015 году.<br />Мы:<br /><br />– Разрабатываем и продаём продукты на нейронных сетях<br />– Развиваем первую российскую библиотеку для нейронных сетей<br />– Консультируем и помогаем бизнесам понять, как получить пользу от технологий машинного обучения<br />– Ведём научные исследования в направлении обучения без учителя, капсул Джеффри Хинтона и трекинга объектов на видео<br /><br />С 2015 года мы являемся резидентами Сколково и получаем поддержку Фонда Сколково.',
    },
    partners: {
      heading: 'Наши партнеры',
    },
    contacts: {
      heading: 'Контакты',
      tel: '+7 495 995-58-72',
      telLink: '+74959955872',
      email: 'info@ashmanov.net',
      address: '121357, Москва, ул. Верейская, д. 29, стр. 134.',
      formHeading: 'Напишите нам',
      form: {
        nameLabel: 'Представьтесь, пожалуйста',
        namePlace: 'Иван Иванов',
        emailLabel: 'Email',
        emailPlace: 'example@example.com',
        tel: 'Телефон',
        textareaPlace: 'Введите ваше сообщение',
        policy: '',
        acceptPolicy: 'Согласен с условиями',
        submitBtn: 'Отправить',
        success: 'Спасибо',
      },
      infoLink: {
        workTitle: 'По вопросам, связанным с работой или стажировкой в компании, пишите на e-mail:',
        workEmail: 'hr@ashmanov.net',
        pressTitle: 'Контакты для прессы:',
        pressEmail: 'pr@ashmanov.net',
        workingConditionsLink: 'Перечень рекомендуемых мероприятий по улучшений условий труда',
        statementOfLaborLink: 'Сводная ведомость результатов проведения специальной оценки условий труда'
      }
    },
    demos: {
      heading: 'Демо',
      subheading: 'Вы можете посмотреть примеры наших продуктов и технологий на нейронных сетях прямо в вашем браузере',
      cards: [
        /*{
          label: 'Детектор объектов',
          text: 'Нейросети для классификации фотографий.',
          href: '/tagger',
        },*/
        {
          label: 'Детектор очков',
          text: 'Нейросети для анализа предметов.',
          href: '/glasses/',
        },
        {
          label: 'Детектор локаций',
          text: 'Нейросети для определения типа локации по фото.',
          href: '/places/',
        },
        {
          label: 'Стилизатор',
          text: 'Технология Style Transfer позволяет создавать произведения искусства из обычных фотографий.',
          href: '/stylist/',
        },
        {
          label: 'Детектор NSFW-контента',
          text: 'Находим порно и эротические снимки с помощью нейросетей.',
          href: '/nsfw/',
        },
        {
          label: 'Распознавание лиц',
          text: 'Скоро появится на сайте!',
          href: '/#',
        },
      ],
    },
    libs: {
      heading: 'Библиотека PuzzleLib',
      text:
        'Нейросетевая библиотека PuzzleLib используется для конструирования, обучения и эксплуатации нейронных сетей. Библиотека поддерживает все современные виды нейронных сетей и использует для работы графические ускорители GPU. Библиотека работает на большинстве операционных систем: Windows, Linux, macOS, iOS.',
      btnText: 'Подробнее',
    },
    products: {
      heading: 'Продукты',
      cards: [
        {
          label: 'Детекторы объектов на фото и видео',
          text:
            'Мы разрабатываем алгоритмы выделения и классификации объектов на фото и видео. Детекторы работают со скоростью порядка 100 кадров в секунду на видеопотоке 640х480. Точность и полнота зависят от решаемой задачи и от требуемой скорости работы.',
          href: '/contacts/',
          btn: 'Подробнее',
        },
        /* {
          label: 'Анализ текстов',
          text: 'Мы разработали технологию классификации текстов на естественном языке при помощи нейронных сетей.',
          href: '/contacts/',
          btn: 'Посмотреть',
        }, */
        {
          label: 'Face Radar',
          text: 'Система распознавания лиц Face Radar позволяет осуществлять автоматическую идентификацию лиц, половозрастных признаков и эмоций по видеоизображению.',
          href: '/faceradar/',
          btn: 'Посмотреть',
        },
        {
          label: 'Библиотека PuzzleLib',
          text:
            'Нейросетевая библиотека PuzzleLib используется для конструирования, обучения и эксплуатации нейронных сетей. Библиотека поддерживает все современные виды нейронных сетей и использует для работы графические ускорители GPU.',
          href: '/puzzlelib/',
          btn: 'Узнать',
        },
      ],
    },
    reviews: {
      cards: [
        {
          img: 'IMG',
          name: 'Анатолий Соловьев',
          position: 'Исполнительный директор',
          company: 'Нефтянка',
          text:
            'Значимость этих проблем настолько очевидна, что консультация с широким активом требуют определения и уточнения систем массового участия. Товарищи! дальнейшее развитие различных форм деятельности позволяет выполнять важные задания по разработке систем массового участия. Не следует, однако забывать, что сложившаяся структура организации обеспечивает широкому кругу (специалистов) участие',
          href: '/',
          linkText: 'Читать далее',
        },
        {
          img: 'IMG',
          name: 'Анатолий Соловьев',
          position: 'Исполнительный директор',
          company: 'Нефтянка',
          text:
            'Значимость этих проблем настолько очевидна, что консультация с широким активом требуют определения и уточнения систем массового участия. Товарищи! дальнейшее развитие различных форм деятельности позволяет выполнять важные задания по разработке систем массового участия. Не следует, однако забывать, что сложившаяся структура организации обеспечивает широкому кругу (специалистов) участие',
          href: '/',
          linkText: 'Читать далее',
        },
        {
          img: 'IMG',
          name: 'Анатолий Соловьев',
          position: 'Исполнительный директор',
          company: 'Нефтянка',
          text:
            'Значимость этих проблем настолько очевидна, что консультация с широким активом требуют определения и уточнения систем массового участия. Товарищи! дальнейшее развитие различных форм деятельности позволяет выполнять важные задания по разработке систем массового участия. Не следует, однако забывать, что сложившаяся структура организации обеспечивает широкому кругу (специалистов) участие',
          href: '/',
          linkText: 'Читать далее',
        },
      ],
    },
  },
  productsPage: {
    title: 'Продукты - Нейросети Ашманова',
    btnMore: 'Подробнее',
    products: {
      heading: 'Продукты',
      cards: [
        {
          label: 'Библиотека PuzzleLib',
          text:
            'Нейросетевая библиотека PuzzleLib используется для конструирования, обучения и эксплуатации нейронных сетей. Библиотека поддерживает все современные виды нейронных сетей.',
        },
        {
          label: 'Анализ текстов',
          text: 'Мы разработали технологию классификации текстов на естественном языке при помощи нейронных сетей.',
        },
        {
          label: 'Детекторы объектов',
          text:
            'Мы разрабатываем алгоритмы выделения и классификации объектов на фото и видео. Детекторы работают со скоростью порядка 100 кадров в секунду на видеопотоке 640x480.',
        },
        {
          label: 'Нейросети на Jetson',
          text:
            'Наша библиотека может работать на встраиваемом компьютере NVIDIA Jetson TX2 или Xavier. Это позволяет запускать нейронные сети в роботах, автомобилях и беспилотниках.',
        },
        {
          label: 'Нейросети на iPhone',
          text:
            'Наша библиотека PuzzleLib поддерживает работу нейронных сетей на устройствах Apple (от iPhone 5s и новее).',
        },
        {
          label: 'Нейросети на Android',
          text:
            'PuzzleLib Mobile можно использовать для построения приложений с дополненной реальностью, а также приложений для распознавания данных на фото и видео.',
        },
        /* {
          label: 'Face Radar',
          text:
            'Система распознавания лиц Face Radar позволяет осуществлять автоматическую идентификацию лиц, половозрастных признаков и эмоций по видеоизображению.',
        }, */
      ],
    },
  },
  puzzleLibPage: {
    title: 'PuzzleLib - Нейросети Ашманова',
    heading: 'Библиотека PuzzleLib',
    btnMore: 'Подробнее',
    descrBlock: {
      heading: 'Что такое PuzzleLib',
      text:
        'PuzzleLib – это нейросетевая библиотека, т. е. ПО, которое позволяет собрать нейронную сеть под конкретную задачу, визуализировать её архитектуру, запустить обучение этой нейронной сети и выгрузить обученную сеть для использования. <br /> <br /> Нейросетевая библиотека PuzzleLib внесена в реестр российского ПО под номером <a href="https://reestr.digital.gov.ru/request/196243/?sphrase_id=2183298" target="_blank">4924 от 03.12.2018</a> <br /> <br /> Аналоги: иностранные библиотеки TensorFlow, Torch, CNTK, разработанные компаниями Google, Facebook, Microsoft.',
    },
    reqBlock: {
      heading: 'Что для библиотеки важно?',
      list: [
        'Алгоритмы: иметь поддержку классических нейросетевых алгоритмов, которые в рамках библиотеки реализуются в виде отдельных модулей вычислений.',
        'Поддержка CPU и GPU: обучение нейронной сети выгоднее проводить на GPU, но поддержка CPU может потребоваться для случаев, когда нет возможности использовать дорогостоящие GPU.',
        'Кроссплатформенность: нужна возможность запускать нейросети на различных операционных системах и процессорных архитектурах, в частности – на мобильных устройствах.',
        'Скорость: в задачах с большими данными обучение нейросети может занимать дни. Ускорение в 1.5-2 раза – уже существенно для рабочего процесса. Кроме того, быстрая обработка данных важна в задачах, где нужна работа в реальном времени (например, анализ видео на борту).',
      ],
    },
    advantageBlock: {
      heading: 'Преимущества PuzzleLib',
      list: [
        'Высокая скорость: мы используем только низкоуровневые утилиты для видеокарт NVIDIA и AMD и эффективно задействуем ресурсы процессора.',
        'Поддерживаем работу на CPU и GPU (центральные процессоры и видеокарты).',
        'Библиотека может работать на всех основных операционных системах (семейство Linux, Windows, Mac OS, iOS и Android).',
        'Автоматическая оптимизация вычислений. В некоторых случаях это ускоряет обучение нейросети в 2 раза.',
        '<b>Более 60 видов нейросетевых модулей</b>, которые позволяют строить свёрточные, рекуррентные нейросети и сети с произвольными графами вычислений. Список модулей – в соответствующем разделе ниже.',
        '<b>Порог входа</b> в PuzzleLib для пользователя гораздо ниже, чем для других библиотек.',
        'PuzzleLib – полностью отечественная разработка.',
      ],
    },
    modulesBlock: {
      heading: 'Список модулей, реализованных в PuzzleLib',
      descr:
        'Любая нейросеть представляет из себя граф вычислений: каждый элемент графа – это модуль нашей библиотеки, производящий операции над переданными ему данными. Ниже приводится список модулей, которые сейчас есть в PuzzleLib. Они позволяют собрать любую современную нейронную архитектуру.',
      modules: [
        {
          name: 'Базовые модули:',
          list: [
            'Activation (Sigmoid, Tanh, ReLU, ELU, LeakyReLU, SoftMaxPlus)',
            'AvgPool (1D, 2D, 3D)',
            'BatchNorm (1D, 2D, 3D, ND)',
            'Conv (1D, 2D, 3D, ND)',
            'CrossMapLRN',
            'Deconv (1D, 2D, 3D, ND)',
            'Dropout (1D, 2D)',
            'GroupLinear',
            'InstanceNorm2D',
            'KMaxPool',
            'LCN',
            'Linear',
            'LRN',
            'MapLRN',
            'MaxPool (1D, 2D, 3D)',
            'MaxUnpool2D',
            'Pool (1D, 2D, 3D)',
            'RNN',
            'SoftMax',
            'SubtractNorm',
            'Upsample (2D, 3D)',
          ],
        },
        {
          name: 'Специальные модули:',
          list: ['Embedder', 'SpatialTf'],
        },
        {
          name: 'Вспомогательные модули:',
          list: [
            'Add',
            'Concat',
            'DepthConcat',
            'Flatten',
            'Glue',
            'Identity',
            'MoveAxis',
            'Mul',
            'MulAddConst',
            'NoiseInjector',
            'Penalty',
            'Replicate',
            'Reshape',
            'Split',
            'Sum',
            'SwapAxes',
            'Tile',
            'ToList',
            'Transpose',
          ],
        },
      ],
    },
    algBlock: {
      heading: 'Список алгоритмов оптимизации, реализованных в PuzzleLib',
      descr:
        'Для обучения нейронной сети необходимо итерационно обновлять её веса с помощью алгоритма, называемого «оптимизатором». Для разных задач (например, регрессия, классификация текстов или изображений) нужны разные оптимизаторы.',
      list: [
        'AdaDelta',
        'AdaGrad',
        'Adam',
        'Hooks',
        'LBFGS',
        'MomentumSGD',
        'NesterovSGD',
        'RMSProp',
        'RMSPropGraves',
        'SGD',
        'SMORMS3',
      ],
    },
    documentsBlock: {
      heading: 'Документы',
      documents: [
        /*{ title: 'Описание PuzzleLib', id: 'Описание PuzzleLib' },*/
        { title: 'Инструкция по установке нейросетевой библиотеки PuzzleLib', id: 'Инструкция по установке нейросетевой библиотеки PuzzleLib' },
        { title: 'СК ДП Сопровождение ПО Нейросети Ашманова Жизненный цикл ПО', id: 'СК ДП Сопровождение ПО Нейросети Ашманова Жизненный цикл ПО' },
        { title: 'Лицензия PuzzleLib', id: 'Лицензия PuzzleLib' },
        { title: 'Прайс-лист PuzzleLib', id: 'Прайс-лист PuzzleLib' },
      ],
    },
  },
  faceRadarPage: {
    title: 'FaceRadar - Нейросети Ашманова',
    heading: 'Технология распознавания лиц Face Radar',
    descriptionBlock: {
      heading: 'Удобный и практичный метод идентификации без пароля',
      text: 'Каждый человек имеет уникальное строение лица. Система распознавания лиц Face Radar позволяет осуществлять автоматическую идентификацию лиц, половозрастных признаков и эмоций по видеоизображению.',
      textBlock: 'Для обучения нейронной сети мы используем нашу Open Source нейросетевую библиотеку <a href="/ru/puzzlelib/" target="PuzzleLib">PuzzleLib</a>, которая является полностью отечественным продуктом и работает быстрее аналогов.',
      btnText: 'Запросить демо',
    },
    areasUseBlock: {
      heading: 'Области применения технологии распознавания лиц Face Radar',
      blocks: [
        {
          title: 'Безопасность',
          imgName: 'safety',
          ulData: ['Видеоаналитика помещений', 'Выявление нарушителей на стадионе во время матча', 'Контроль доступа посторонних на охраняемые объекты', 'Выявление нарушителей с помощью видеорегистраторов']
        },
        {
          title: 'Банковский сектор',
          imgName: 'bank',
          ulData: ['Сбор биометрических данных', 'Быстрая идентификация клиента', 'Оплата услуг', 'Верификация банковских карт']
        },
        {
          title: 'Ритейл',
          imgName: 'retail',
          ulData: ['Определение портрета покупателя', 'Выявление нарушителей на стадионе во время матча', 'Анализ поведения покупателя', 'Управление рабочим временем', 'Предотвращения магазинных краж', 'Контроль продажи алкоголя лицам младше 18 лет']
        },
        {
          title: 'Образование',
          imgName: 'education',
          ulData: ['Удаленная идентификация для тестирования студентов', 'Наблюдение и контроль за проведением экзаменов']
        },
      ]
    },
    opportunitiesBlock: {
      heading: 'Возможности технологии Face Radar',
      blocks: [
        {
          title: 'Обнаружение людей на фото и видео',
          infoText: '',
          imgName: 'people-detection',
        },
        {
          title: 'Подсчет людей',
          infoText: 'Система находит лица и производит их подсчет по изображению или видео БЕЗ его идентификации',
          imgName: 'people-counting',
        },
        {
          title: 'Идентификация и верификация людей',
          infoText: 'Система идентифицирует лица, сопоставляя их с базой данных заказчика. В качестве распознавания лиц мы используем Inception ResNet',
          imgName: 'people-identifying',
        },
        {
          title: 'Определение пола и возраста для формирования возраста человека по лицу',
          infoText: 'В погрешности +/-5 лет лицу присваивается возраст от 7 до 70 лет (причем 7 - это любой возраст от 0 до 7, а 70 - 70 и старше).',
          imgName: 'age-determination',
        },
        {
          title: 'Распознавание эмоций лица',
          infoText: 'Наша нейросеть на основе ResNet решает сразу и предыдущую задачу, и эту. Такой подход экономит не только используемые ресурсы, но и повышает точность распознавания',
          imgName: 'emotion-recognition',
        },
      ],
    },
    howWorksBlock: {
      heading: 'Как работает технология распознавания лиц?',
      blocks: [
        {
          title: 'Шаг 1. Обнаружение лица',
          text: 'Неважно, один человек или в толпе, камера распознает его лицо. Лучший результат достигается в том случае, когда человек смотрит прямо в камеру.',
          imgName: 'stage1',
        },
        {
          title: 'Шаг 2. Анализ лица',
          text: 'Следующим шагом делается снимок лица и начинается его обработка. Каждое лицо составлено из различимых ориентиров или узловых точек. Face Radar анализирует узловые точки, например, такие, как расстояние между глаз.',
          imgName: 'stage2',
        },
        {
          title: 'Шаг 3. Конвертация изображения в данные',
          text: 'Далее с помощью анализа происходит превращение лица в набор чисел (эмбеддинг). Иными словами, черты лица становятся числовым кодом (faceprint). Также, как и уникальный отпечаток пальца, каждый человек имеет свой собственный отпечаток лица.',
          imgName: 'stage3',
        },
        {
          title: 'Шаг 4. Поиск совпадений',
          text: 'Заключительным этапом является сравнение кода с базой данных отпечатков лиц. В этой базе данных имеются фотографии с идентификаторами, которые можно сравнивать.',
          imgName: 'stage4',
        },
      ],
      gifName:'gif-how-works',
      bottomText: 'Технология Face Radar, созданная с помощью нейронных сетей, не требует дорогостоящего оборудования и с легкостью встраивается в существующие бизнес-процессы компании'
    },
    feedbackBlock: {
      heading: 'Остались вопросы или необходимо больше информации о технологии Face Radar для вашего бизнеса? Оставьте заявку, и мы ответим на любые вопросы касательно внедрения, стоимости и сроков реализации',
      
    },
  },
  consultingPage: {
    title: 'Консалтинг - Нейросети Ашманова',
    btnMore: 'Подробнее',
    heading: 'Консалтинг',
    consultingText: {
      heading: 'Наши услуги',
      contactBtn: 'Получить пользу',
      list: [
        {
          label: 'Консультируем, как в вашем бизнесе получить пользу от ИИ',
          icon: { class: 'fas', name: 'hands-helping' },
        },
        {
          label: 'Помогаем проработать задачу',
          icon: { class: 'fas', name: 'project-diagram' },
        },
        {
          label: 'Пишем техническое задание',
          icon: { class: 'fas', name: 'file-signature' },
        },
        {
          label: 'Обучаем команду ваших программистов',
          icon: { class: 'fas', name: 'chalkboard-teacher' },
        },
      ],
    },
    consultingCards: {
      heading: 'По отраслям',
      contactBtn: 'Оптимизировать бизнес',
      cards: [
        {
          label: 'Консалтинг PuzzleLib',
          text:
            'Нейросетевая библиотека PuzzleLib используется для конструирования, обучения и эксплуатации нейронных сетей. Библиотека поддерживает все современные виды нейронных сетей.',
        },
        {
          label: 'Консалтинг PuzzleLib',
          text:
            'Нейросетевая библиотека PuzzleLib используется для конструирования, обучения и эксплуатации нейронных сетей. Библиотека поддерживает все современные виды нейронных сетей.',
        },
        {
          label: 'Консалтинг PuzzleLib',
          text:
            'Нейросетевая библиотека PuzzleLib используется для конструирования, обучения и эксплуатации нейронных сетей. Библиотека поддерживает все современные виды нейронных сетей.',
        },
        {
          label: 'Консалтинг PuzzleLib',
          text:
            'Нейросетевая библиотека PuzzleLib используется для конструирования, обучения и эксплуатации нейронных сетей. Библиотека поддерживает все современные виды нейронных сетей.',
        },
        {
          label: 'Консалтинг PuzzleLib',
          text:
            'Нейросетевая библиотека PuzzleLib используется для конструирования, обучения и эксплуатации нейронных сетей. Библиотека поддерживает все современные виды нейронных сетей.',
        },
        {
          label: 'Консалтинг PuzzleLib',
          text:
            'Нейросетевая библиотека PuzzleLib используется для конструирования, обучения и эксплуатации нейронных сетей. Библиотека поддерживает все современные виды нейронных сетей.',
        },
      ],
    },
  },
  demoPage: {
    title: 'Демо - Нейросети Ашманова',
    heading: 'Демо',
    btnBack: 'Назад',
    uploadText: 'Перетащите изображение сюда',
    samplesHeading: 'Выберите стиль',
    demos: {
      heading: 'Демо',
      subheading: 'Вы можете посмотреть примеры наших продуктов и технологий на нейронных сетях прямо в вашем браузере',
      cards: [
        /*{
          label: 'Детектор объектов',
          text: 'Нейросети для классификации фотографий',
          href: '/tagger',
        },*/
        {
          label: 'Детектор очков',
          text: 'Нейросети для анализа сэлфи',
          href: '/glasses/',
        },
        {
          label: 'Детектор локаций',
          text: 'Нейросети для определения типа локации по фото',
          href: '/places/',
        },
        {
          label: 'Стилизатор',
          text: 'Технология Style Transfer для создания произведений искусства из обычных фотографий',
          href: '/stylist/',
        },
        {
          label: 'Детектор NSFW-контента',
          text: 'Находим порно и эротические снимки с помощью нейросетей',
          href: '/nsfw/',
        },
        {
          label: 'Распознавание лиц',
          text: 'Скоро появится на сайте!',
          href: '/#',
        },
      ],
    },
    /*demoTagger: {
      heading: 'Детектор объектов',
      description: `<p>Детектор реализован на базе глубокой свёрточной сети. Нейронную сеть обучили распознавать несколько сотен видов объектов из разных областей.</p>
      <p>Использовалась модификация глубокой архитектуры ResNet, фреймворк нейронных сетей - PuzzleLib. Детектор работает на видеокартах NVIDIA GTX 1080.</p>
      <p>Попробуйте загрузить фотографию, после чего детектор выдаст результат распознавания.</p>`,
    },*/
    demoGlasses: {
      heading: 'Детектор очков',
      description: `<p>Применение нейросетей для анализа изображений с людьми. Можно использовать для таргетирования рекламы в социальных сетях. Предоставляем в виде онлайн-сервиса, есть API.</p>
      <p>Использовалась модификация глубокой архитектуры ResNet, фреймворк нейронных сетей - PuzzleLib. Детектор работает на видеокартах NVIDIA GTX 1080.</p>
      <p>Попробуйте загрузить фотографию, после чего детектор выдаст результат распознавания.</p>`,
    },
    demoPlaces: {
      heading: 'Детектор локаций',
      description: `<p>Детектор реализован на базе глубокой свёрточной сети. Нейронная сеть распознаёт несколько сотен типов локаций (рестораны, бары, музеи и пр.)</p>
      <p>Мы используем модификацию глубокой архитектуры ResNet, фреймворк нейронных сетей - PuzzleLib. Работает на видеокарте NVIDIA GTX 1080.</p>
      <p>Попробуйте загрузить фотографию, после чего детектор выдаст результат распознавания.</p>`,
    },
    demoStylist: {
      heading: 'Стилизатор',
      downloadBtn: 'Скачать',
      description: `<p>Стилизация изображения происходит с помощью технологии Style Transfer. Обработка делается за один проход фото через нейронную сеть - на два порядка быстрее итерационных методов.</p>
      <p>Эта технология - демонстрация того, что нейронные сети можно применять не только для анализа или классификации данных. Стилизация изображения - хороший пример использования нейросетей для развлечения, искусства и дизайна.</p>`,
    },
    demoNSFW: {
      heading: 'Детектор NSFW контента',
      description: `<p>Детектор реализован на базе глубокой свёрточной сети. Нейронную сеть обучили распознавать, насколько вероятно, что предоставленный контент принадлежит категории 'Материалы для взрослых'.</p>
      <p>Использовалась модификация глубокой архитектуры ResNet, фреймворк нейронных сетей - PuzzleLib. Детектор работает на видеокартах NVIDIA GTX 1080.</p>
      <p>Попробуйте загрузить фотографию, после чего детектор выдаст результат распознавания.</p>`,
    },
  },
  partnersPage: {
    title: 'Наши партнёры - Нейросети Ашманова',
    heading: 'Наши партнёры',
    partnersBlock: [
      {
        imgName: 'fasie',
        imgLink: 'http://fasie.ru/',
        name: 'Фонд содействия инновациям',
        description: `Компания «Нейросети Ашманова» была поддержана <a href="http://fasie.ru/" target="_blank">Фондом содействия инновациям</a>. В 2015 г. команда компании участвовала в программе СТАРТ-1 с проектом «Puzzle: система распознавания образов в изображениях и видео, построенная на базе нейронных сетей». <br><br>
В 2018 году компания была поддержана Фондом по программе Старт-2 с проектом по теме «Проведение исследований в области внедрения программного комплекса по распознаванию образов в продукты IT-компаний. Исследования и разработки для поддержки анализа видео. Исследования в области поддержки множества операционных систем, включая мобильные операционные системы».`
      },
      {
        imgName: 'nvidia',
        imgLink: 'https://www.nvidia.com/ru-ru/about-nvidia/',
        name: 'NVIDIA',
        description: `Nvidia – наши партнеры по направлению машинного обучения. Во многих проектах мы используем видеокарты и программное обеспечение Nvidia, и получаем от них техническую поддержку. <br><br>
Компания <a href="https://www.nvidia.com/ru-ru/about-nvidia/" target="_blank">Nvidia</a> – лидирующий игрок в направлении искусственного интеллекта, продукты которого наиболее популярны для решения задач машинного обучения для бизнеса. `
      },
      {
        imgName: 'intel',
        imgLink: 'https://www.intel.ru/content/www/ru/ru/homepage.html',
        name: 'Intel',
        description: `Мы сотрудничаем с Intel по направлению машинного обучения. <br><br>
<a href="https://www.intel.ru/content/www/ru/ru/homepage.html" target="_blank">Intel</a> – один из крупнейших производителей электронных устройств и компьютерных компонентов, включая микропроцессоры. Сегодня компания располагает обширным портфелем технологий искусственного интеллекта — от центров обработки данных до граничных систем и периферийных устройств. Корпорация предлагает аппаратные и программные продукты для всех областей искусственного интеллекта, включая машинное и глубинное обучение. Мы периодически используем продукты Intel для нейросетевых вычислений. Например, здесь мы подробно описали свои впечатления о работе с нейрочипом Intel Movidius. `
      },
      {
        imgName: 'mcst',
        imgLink: 'http://www.mcst.ru/',
        name: 'МЦСТ',
        description: `<a href="http://www.mcst.ru/" target="_blank">АО «МЦСТ»</a> разрабатывает универсальные высокопроизводительные российские микропроцессоры и вычислительные комплексы. Одним из них является современный микропроцессор <a href="http://elbrus.ru/" target="_blank">“Эльбрус”</a>. Мы экспортировали библиотеку нейронных сетей <a href="https://ashmanov.net/ru/puzzlelib" target="_blank">PuzzleLib</a> на Эльбрус и поддерживаем работу системы распознавания лиц на нейронных сетях.`
      },
      {
        imgName: 'mfti',
        imgLink: 'https://mipt.ru/science/labs/',
        name: 'МФТИ',
        description: `Осенью 2018 года Станислав Ашманов открыл Лабораторию нейросетевых технологий и прикладной лингвистики в МФТИ. Сотрудники лаборатории проводят исследования в области глубокого обучения (Deep Learning) и ведут разработки собственной технологии по распознаванию и синтезу речи. Скоро у лаборатории появится своя страница, где мы подробно будем обо всем рассказывать.<br><br>
Также мы являемся участником <a href="https://mipt.ru/ai/" target="_blank">Центра “Искусственного интеллекта”</a>, созданного в рамках программы Национальной Технологической Инициативы (НТИ). Работа центра по направлению «Искусственный интеллект» ведется в составе консорциума, в состав которого также входит наша компания.`
      },
      // {
      //   imgName: 'mgu',
      //   name: 'Московский государственный университет имени М.В.Ломоносова (МГУ)',
      //   description: ''
      // },
      // {
      //   imgName: 'itmo',
      //   name: 'Санкт-Петербургский национальный исследовательский университет информационных технологий, механики и оптики (ИТМО)',
      //   description: ''
      // },
      {
        imgName: 'infowatch',
        imgLink: 'https://www.infowatch.ru/',
        name: 'Infowatch',
        description: `Наша компания является партнёром группы компаний АО “Инфовотч”. <br><br>
Группа компаний <a href="https://www.infowatch.ru/solutions/" target="_blank">InfoWatch</a> — разработчик комплексных решений для обеспечения информационной безопасности организаций. Продуктовый портфель компании содержит эффективные решения по защите предприятий от наиболее актуальных внутренних и внешних угроз ИБ, информационных атак, а также для обеспечения промышленной безопасности предприятий.`
      },
      {
        imgName: 'fefu',
        imgLink: 'https://www.dvfu.ru/',
        name: 'Дальневосточный федеральный университет (ДВФУ)',
        description: `Мы входим в <a href="https://www.dvfu.ru/news/fefu-news/the_university_held_the_first_meeting_of_the_consortium_sti_for_neurotechnology_vr_and_ar/" target="_blank">консорциум</a> НТИ по нейротехнологиям, технологиям виртуальной и дополненной реальности (VR/AR), который был создан в 2017 году на базе ДВФУ. <br><br>
Основа стратегии консорциума состоит в синхронной разработке программно-аппаратных комплексов, которые расширят возможности взаимодействия и интеграции с существующей экосистемой. Планируется, что к 2021 году должна появиться не только конкурентоспособная платформа для разработки продуктов в области VR/AR, но и мощное профессиональное сообщество, которое действует на принципах open source и формирует собственные рыночные стандарты.`
      },
      {
        imgName: 'nanosemantics',
        imgLink: 'https://nanosemantics.ai/?utm_source=ashmanov_net&utm_medium=parnter_page',
        name: 'Наносемантика',
        description: `Наши коллеги и соседи по офису, ведущий разработчик чат-ботов в России и странах СНГ – Лаборатория Наносемантика. <br><br>
В <a href="https://nanosemantics.ai/proekty/?utm_source=ashmanov_net&utm_medium=partner_page" target="_blank">Наносемантике</a> делают умных чат-ботов, которые способны общаться на естественном языке, оказывают внешнюю и внутреннюю поддержку для клиентов и сотрудников компании, вне зависимости от ее сферы деятельности (банки, телеком, ритейл, услуги). <br><br>
С недавних пор сотрудники обеих компаний – это одна большая и дружная команда. Мы совместно работаем над чат-ботами на нейросетях и машинном обучении. Также часть сотрудников компаний входит в группу по разработке распознавания и синтеза речи. `
      },
      {
        imgName: 'progkids',
        imgLink: 'http://progkids.com/?utm_source=ashmanov',
        name: 'ProgKids',
        description: `Наш партнёр <a href="http://progkids.com/?utm_source=ashmanov" target="_blank">ProgKids</a> - онлайн платформа для изучения основ программирования. На платформе можно обучаться самостоятельно по готовым урокам или с преподавателем в формате видеозвонка. База уроков постоянно пополняется. Ваш ребенок пишет программы сам и видит результат в игре Minecraft.<br><br>
Для изучения доступен Snap! - блочный язык программирования (аналог Scratch) и Python.`
      },
    ],
    form: {
      title: 'Мы рассматриваем любые предложения по сотрудничеству. Если вы – университет, поставщик оборудования или ПО, занимаетесь разработками в области искусственного интеллекта, собираете данные для обучения нейросетей – заполните форму ниже и опишите суть вашего предложения. Мы обязательно вам ответим.',
      nameLabel: 'Напишите, как к вам обращаться',
      namePlace: 'Иван Иванов',
      emailLabel: 'Ваш email',
      emailPlace: 'example@example.com',
      tel: 'Ваш телефон',
      textareaPlace: 'Коротко расскажите о сути вашего предложения/идеи. Также можете рассказать, чем занимаетесь сами.',
      submitBtn: 'Отправить',
      success: 'Спасибо за обращение!',
    },
  },
  blogPage: {
    title: 'Блог',
    moreBtn: 'Загрузить ещё',
  },
  tagsPage: {
    title: 'Теги',
    allTags: 'Все теги',
    postsWithTag: 'Записи с тегом',
  },
  footer: {
    heading: 'Контакты',
    tel: '+7 495 995-58-72',
    telLink: '+74959955872',
    email: 'info@ashmanov.net',
    address: '121357, Москва, ул. Верейская, д.29, стр. 134.',
    copyright: '© Нейросети Ашманова 2015 ',
  },
}
