module.exports = {
  language: 'EN',
  logo: {
    text: 'Ashmanov <br/>Neural Networks',
  },
  nav: [
    { title: 'Home', href: '/' },
    { title: 'Products', href: '/products/' },
    { title: 'Consulting', href: '/consulting/' },
    { title: 'Demo', href: '/demos/' },
    { title: 'PuzzleLib', href: '/puzzlelib/' },
    { title: 'Contact Us', href: '/#contact' },
  ],
  contactBtn: 'Contact us',
  frontpage: {
    title: 'Ashmanov Neural Networks—artificial intelligence and machine learning methods',
    banner: {
      h1: ' Neural network-based apps and services',
      config: [
        {
          text:
            'We develop products based on neural networks <br />and machine learning.<br /> We advise on employing machine learning.',
          btn: 'Read more',
          href: '/#',
        },
        {
          text:
            'Would you like to learn how you can benefit from employing machine learning solutions? We are happy to offer you a first consultation session for free.',
          btn: 'Submit request',
          href: '/#contact',
        },
        {
          text: 'PuzzleLib framework: we have developed <br />the most fast-speed neural network building framework.',
          btn: 'Read more',
          href: '/puzzlelib/',
        },
      ],
    },
    about: {
      heading: 'About Us',
      text: `“Ashmanov Neural Networks” company was founded in 2015 by Stanislav Ashmanov and Igor Modyaev. <br />What we do:<br /><br />–We develop and sell neural network-based products <br />–We have created and are advancing Russia’s first deep learning framework <br />–We advise businesses and help our clients identify how to benefit from introducing machine learning technologies <br />– We conduct research on unsupervised learning, on Geoffrey Hinton’s capsule networks, and on object tracking in video <br /><br />Starting in 2015, we have been a Skolkovo resident and we have been receiving Skolkovo Foundation support.`,
    },
    contacts: {
      heading: 'Contacts',
      tel: '+7 495 995-58-72',
      telLink: '+74959955872',
      email: 'info@ashmanov.net',
      address: 'Vereyskaya st., 29/134, Moscow 121357.',
      formHeading: 'Contact us',
      form: {
        nameLabel: 'Please introduce yourself:',
        namePlace: 'John Doe',
        emailLabel: 'Email',
        emailPlace: 'Your email',
        tel: 'Phone',
        textareaPlace: 'Please type your message here',
        policy: '',
        acceptPolicy: 'I agree to the Terms and Conditions',
        submitBtn: 'Submit',
        success: 'Thank you',
      },
      infoLink: {
        workTitle: 'If you are interested in a job or internship in the company, please contact us by email:',
        workEmail: 'hr@ashmanov.net',
        pressTitle: 'For media requests:',
        pressEmail: 'pr@ashmanov.net',
        workingConditionsLink: 'List of recommended measures to improve working conditions',
        statementOfLaborLink: 'Summary statement of the results of a special assessment of working conditions'
      }
    },
    demos: {
      heading: 'Demo',
      subheading: 'You can view our sample neural network-based products and technologies right in your browser',
      cards: [
        {
          label: 'Object detection',
          text: 'Using neural networks for image classification.',
          href: '/tagger',
        },
        {
          label: 'Glasses detection',
          text: 'Using neural networks for analyzing selfies.',
          href: '/glasses/',
        },
        {
          label: 'Location detection',
          text: 'Using neural networks to identify location type from a photo.',
          href: '/places/',
        },
        {
          label: 'Stylization',
          text: 'Style Transfer technology allows to create artsy masterpieces from regular photos.',
          href: '/stylist/',
        },
        {
          label: 'NSFW-content detection',
          text: 'Using neural networks to detect unsafe content: nudity and pornography.',
          href: '/nsfw/',
        },
        {
          label: 'Ashmanov <br/>Neural Networks',
          text: 'Contact us.',
          href: '/#contact',
        },
      ],
    },
    libs: {
      heading: 'PuzzleLib Framework',
      text: `PuzzleLib neural network framework is designed for building, training, and operating neural networks. The framework supports all cutting-edge types of neural networks and utilizes GPU accelerators. The library is available through the majority of operating systems: Windows, Linux, MacOS, iOS.`,
      btnText: 'Read more',
    },
    products: {
      heading: 'Products',
      cards: [
        {
          label: 'Object detection for photo and video',
          text: `We develop algorithms for detecting and classifying objects in photos and videos. Our detectors perform at an average speed of 100 frames per second for a 640х480 video stream. Detection accuracy and exhaustiveness depend on the specific task and the required detection speed.`,
          href: '/contacts/',
          btn: 'Read more',
        },
        {
          label: 'Video Analytics',
          text: `We have developed a solution for classifying natural language text with the help of neural networks.`,
          href: '/contacts/',
          btn: 'Read more',
        },
        {
          label: 'PuzzleLib Framework',
          text: `PuzzleLib neural network framework is designed for building, training, and operating neural networks. The framework supports all cutting-edge types of neural networks and utilizes GPU accelerators.`,
          href: '/puzzlelib/',
          btn: 'Read more',
        },
      ],
    },
    reviews: {
      cards: [
        {
          img: 'IMG',
          name: 'Анатолий Соловьев',
          position: 'Исполнительный директор',
          company: 'Нефтянка',
          text:
            'Значимость этих проблем настолько очевидна, что консультация с широким активом требуют определения и уточнения систем массового участия. Товарищи! дальнейшее развитие различных форм деятельности позволяет выполнять важные задания по разработке систем массового участия. Не следует, однако забывать, что сложившаяся структура организации обеспечивает широкому кругу (специалистов) участие',
          href: '/',
          linkText: 'Читать далее',
        },
        {
          img: 'IMG',
          name: 'Анатолий Соловьев',
          position: 'Исполнительный директор',
          company: 'Нефтянка',
          text:
            'Значимость этих проблем настолько очевидна, что консультация с широким активом требуют определения и уточнения систем массового участия. Товарищи! дальнейшее развитие различных форм деятельности позволяет выполнять важные задания по разработке систем массового участия. Не следует, однако забывать, что сложившаяся структура организации обеспечивает широкому кругу (специалистов) участие',
          href: '/',
          linkText: 'Читать далее',
        },
        {
          img: 'IMG',
          name: 'Анатолий Соловьев',
          position: 'Исполнительный директор',
          company: 'Нефтянка',
          text:
            'Значимость этих проблем настолько очевидна, что консультация с широким активом требуют определения и уточнения систем массового участия. Товарищи! дальнейшее развитие различных форм деятельности позволяет выполнять важные задания по разработке систем массового участия. Не следует, однако забывать, что сложившаяся структура организации обеспечивает широкому кругу (специалистов) участие',
          href: '/',
          linkText: 'Читать далее',
        },
      ],
    },
  },
  productsPage: {
    title: 'Ashmanov Neural Networks Products',
    btnMore: 'Read more',
    products: {
      heading: 'Products',
      cards: [
        {
          label: 'PuzzleLib Framework',
          text:
            'PuzzleLib neural network framework is designed for building, training, and operating neural networks. The framework supports all cutting-edge types of neural network.',
        },
        {
          label: 'Text Analysis',
          text: 'We have developed a solution for classifying natural language text with the help of neural networks.',
        },
        {
          label: 'Object detection',
          text:
            'We develop algorithms for detecting and classifying objects in photos and videos. Our detectors perform at an average speed of 100 frames per second for a 640х480 video stream.',
        },
        {
          label: 'Neural networks built on Jetson',
          text:
            'Our deep learning framework can operate on the Jetson TX2 or Xavier embedded computers. This allows us to launch neural network solutions in robots, cars, and drones.',
        },
        {
          label: 'Neural networks for iPhone',
          text:
            'Our PuzzleLib deep learning framework supports neural network integration for Apple devices (starting from iPhone 5s and up).',
        },
        {
          label: 'Neural networks for Android',
          text:
            'You can use PuzzleLib Mobile for building apps with augmented reality and apps for data recognition in photos and videos.',
        },
      ],
    },
  },
  puzzleLibPage: {
    title: 'PuzzleLib: Ashmanov Neural Networks',
    heading: 'PuzzleLib Deep Learning Framework',
    btnMore: 'Read more',
    descrBlock: {
      heading: 'What is PuzzleLib?',
      text: `PuzzleLib is a neural network framework or a software that allows to build a neural network for a specific task, to visualize its architecture, launch the training of the network, and export the trained neural network for further implementation. <br /> <br /> Some of the analogs include international frameworks TensorFlow, Torch, CNTK, developed by Google, Facebook, Microsoft.`,
    },
    reqBlock: {
      heading: 'What is important in a deep learning framework?',
      list: [
        'Algorithms: it must support the classic neural network algorithms that are executed as separate computing modules inside the framework.',
        'CPU and GPU support: it is more beneficial to train neural networks on GPU, yet CPU support may be essential when there is no opportunity to rely on the expensive GPU.',
        'Cross-platform nature: it is important to have the opportunity to launch neural networks from various operating systems and processor architecture designs, including the mobile devices.',
        'Speed: tasks geared toward big data neural network training may take up several days. Increasing the speed by 1.5-2 times is a significant improvement for the working process. What’s more, fast data processing is essential in tasks performed in real time, such as on-board video analysis.',
      ],
    },
    advantageBlock: {
      heading: 'PuzzleLib Strengths',
      list: [
        'High speed: we employ exclusively low-level utility software for NVIDIA and AMD graphic cards and efficiently leverage on the processor resources.',
        'CPU and GPU support: cornerstone processors and graphic cards.',
        'The framework runs on all key operating systems: Linux family, Windows, MacOS, iOS, and Android.',
        'Automated computing optimization: in some cases it accelerates the speed of neural network training twice.',
        '<b>Over 60 types of available neural network modules</b> that allow to build convolutional and recurrent neural networks and networks with arbitrary graph structure. Below you can find the list of modules.',
        '<b>Entry barrier</b> for PuzzleLib users is significantly lower than in any other frameworks.',
        'PuzzleLib is a Russia’s domestically designed solution.',
      ],
    },
    modulesBlock: {
      heading: 'List of modules implemented in PuzzleLib',
      descr:
        'Any neural network is a computational graph where each element is a module in our framework that can execute operations with the received data. Below is the full list of modules contained by PuzzleLib. They allow you to design any possible cutting-edge neural architecture.',
      modules: [
        {
          name: 'Basic modules:',
          list: [
            'Activation (Sigmoid, Tanh, ReLU, ELU, LeakyReLU, SoftMaxPlus)',
            'AvgPool (1D, 2D, 3D)',
            'BatchNorm (1D, 2D, 3D, ND)',
            'Conv (1D, 2D, 3D, ND)',
            'CrossMapLRN',
            'Deconv (1D, 2D, 3D, ND)',
            'Dropout (1D, 2D)',
            'GroupLinear',
            'InstanceNorm2D',
            'KMaxPool',
            'LCN',
            'Linear',
            'LRN',
            'MapLRN',
            'MaxPool (1D, 2D, 3D)',
            'MaxUnpool2D',
            'Pool (1D, 2D, 3D)',
            'RNN',
            'SoftMax',
            'SubtractNorm',
            'Upsample (2D, 3D)',
          ],
        },
        {
          name: 'Special modules:',
          list: ['Embedder', 'SpatialTf'],
        },
        {
          name: 'Additional modules:',
          list: [
            'Add',
            'Concat',
            'DepthConcat',
            'Flatten',
            'Glue',
            'Identity',
            'MoveAxis',
            'Mul',
            'MulAddConst',
            'NoiseInjector',
            'Penalty',
            'Replicate',
            'Reshape',
            'Split',
            'Sum',
            'SwapAxes',
            'Tile',
            'ToList',
            'Transpose',
          ],
        },
      ],
    },
    algBlock: {
      heading: 'List of optimizing algorithms executed in PuzzleLib',
      descr:
        'For neural network training it is essential to iterationally update its weights with an algorithm known as an “optimizer”. You need different optimizers for different tasks, for example, for regression or for text or image classification.',
      list: [
        'AdaDelta',
        'AdaGrad',
        'Adam',
        'Hooks',
        'LBFGS',
        'MomentumSGD',
        'NesterovSGD',
        'RMSProp',
        'RMSPropGraves',
        'SGD',
        'SMORMS3',
      ],
    },
  },
  consultingPage: {
    title: 'Consulting - Нейросети Ашманова',
    btnMore: 'Подробнее',
    heading: 'Consulting',
    consultingText: {
      heading: 'Our Services',
      contactBtn: 'Get',
      list: [
        {
          label: 'Consult on how your business can benefit from AI',
          icon: { class: 'fas', name: 'hands-helping' },
        },
        {
          label: 'Help to elaborate the task',
          icon: { class: 'fas', name: 'project-diagram' },
        },
        {
          label: 'Write technical requirements',
          icon: { class: 'fas', name: 'file-signature' },
        },
        {
          label: 'Train your team of programmers',
          icon: { class: 'fas', name: 'chalkboard-teacher' },
        },
      ],
    },
    consultingCards: {
      heading: 'По отраслям',
      cards: [
        {
          label: 'Консалтинг PuzzleLib',
          text:
            'Нейросетевая библиотека PuzzleLib используется для конструирования, обучения и эксплуатации нейронных сетей. Библиотека поддерживает все современные виды нейронных сетей.',
        },
      ],
    },
  },
  demoPage: {
    title: 'Demo - Нейросети Ашманова',
    heading: 'Demo',
    btnBack: 'Back',
    uploadText: 'Upload an image',
    demos: {
      heading: 'Демо',
      subheading: 'Вы можете посмотреть примеры наших продуктов и технологий на нейронных сетях прямо в вашем браузере',
      cards: [
        {
          label: 'Детектор объектов',
          text: 'Нейросети для классификации фотографий.',
          href: '/tagger',
        },
        {
          label: 'Детектор очков',
          text: 'Нейросети для анализа сэлфи.',
          href: '/glasses/',
        },
        {
          label: 'Детектор локаций',
          text: 'Нейросети для определения типа локации по фото.',
          href: '/places/',
        },
        {
          label: 'Стилизатор',
          text: 'Технология Style Transfer позволяет создавать произведения искусства из обычных фотографий.',
          href: '/stylist/',
        },
        {
          label: 'Детектор NSFW-контента',
          text: 'Находим порно и эротические снимки с помощью нейросетей.',
          href: '/nsfw/',
        },
        {
          label: 'Детектор NSFW-контента',
          text: 'Находим порно и эротические снимки с помощью нейросетей.',
          href: '/#nsfw-content',
        },
      ],
    },
    demoTagger: {
      heading: 'Детектор объектов',
      description: `<p>Детектор реализован на базе глубокой свёрточной сети. Нейронную сеть обучили распознавать несколько сотен видов объектов из разных областей.</p>
      <p>Использовалась модификация глубокой архитектуры ResNet, фреймворк нейронных сетей - PuzzleLib. Детектор работает на видеокартах NVIDIA GTX 1080 и Tesla K80.</p>
      <p>Попробуйте загрузить фотографию, после чего детектор выдаст результат распознавания.</p>`,
    },
    demoGlasses: {
      heading: 'Детектор объектов',
      description: `<p>Применение нейросетей для анализа изображений с людьми. Можно использовать для таргетирования рекламы в социальных сетях. Предоставляем в виде онлайн-сервиса, есть API.</p>
      <p>Использовалась модификация глубокой архитектуры ResNet, фреймворк нейронных сетей - PuzzleLib. Детектор работает на видеокартах NVIDIA GTX 1080 и Tesla K80.</p>
      <p>Попробуйте загрузить фотографию, после чего детектор выдаст результат распознавания.</p>`,
    },
    demoPlaces: {
      heading: 'Детектор локаций',
      description: `<p>Детектор реализован на базе глубокой свёрточной сети. Нейронная сеть распознаёт несколько сотен типов локаций (рестораны, бары, музеи и пр.)</p>
      <p>Мы используем модификацию глубокой архитектуры ResNet, фреймворк нейронных сетей - PuzzleLib. Работает на видеокарте NVIDIA GTX 1080 и Tesla K80.</p>
      <p>Попробуйте загрузить фотографию, после чего детектор выдаст результат распознавания.</p>`,
    },
    demoStylist: {
      heading: 'Stylizator',
      description: `<p>Стилизация изображения происходит с помощью технологии Style Transfer. Обработка делается за один проход фото через нейронную сеть - на два порядка быстрее итерационных методов.</p>
      <p>Эта технология - демонстрация того, что нейронные сети можно применять не только для анализа или классификации данных. Стилизация изображения - хороший пример использования нейросетей для развлечения, искусства и дизайна.</p>`,
    },
    demoNSFW: {
      heading: 'Детектор NSFW контента',
      description: `<p>Детектор реализован на базе глубокой свёрточной сети. Нейронную сеть обучили распознавать, насколько вероятно, что предоставленный контент принадлежит категории 'Материалы для взрослых'.</p>
      <p>Использовалась модификация глубокой архитектуры ResNet, фреймворк нейронных сетей - PuzzleLib. Детектор работает на видеокартах NVIDIA GTX 1080 и Tesla K80.</p>
      <p>Попробуйте загрузить фотографию, после чего детектор выдаст результат распознавания.</p>`,
    },
  },
  footer: {
    heading: 'Contacts',
    tel: '+7 495 995-58-72',
    telLink: '+74959955872',
    email: 'info@ashmanov.net',
    address: 'Vereyskaya st., 29/134, Moscow 121357.',
    copyright: 'Ashmanov Neural Networks 2015',
  },
}
