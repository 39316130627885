module.exports = {
  language: 'РУС',
  logo: {
    text: 'Нейросети <br/>Ашманова',
  },
  nav: [
    { title: 'Главная', href: '/' },
    { title: 'Продукты', href: '/products/' },
    { title: 'Консалтинг', href: '/consulting/' },
    { title: 'Демо', href: '/demos/' },
    { title: 'PuzzleLib', href: '/puzzlelib/' },
    { title: 'Контакты', href: '/contacts/' },
  ],
  contactBtn: 'Напишите нам',
  frontpage: {
    title: 'Нейросети Ашманова – методы искусственного интеллекта и машинного обучения',
    banner: {
      h1: ' Приложения и сервисы на нейронных сетях',
      config: [
        {
          text:
            'Разрабатываем продукты на нейронных сетях <br />и машинном обучении.<br /> Консультируем по применению машинного обучения.',
          btn: 'Подробнее',
          href: '/consulting/',
        },
        {
          text:
            'Хотите понять, чем машинное обучение может быть полезно вам? Предлагаем встретиться для бесплатной консультации.',
          btn: 'Отправить заявку',
          href: '/#contact',
        },
        {
          text: 'Библиотека PuzzleLib: мы разработали самую <br />быструю библиотеку для построения нейронных сетей.',
          btn: 'Подробнее',
          href: '/puzzlelib/',
        },
      ],
    },
    about: {
      heading: 'О компании',
      text:
        'Компания “Нейросети Ашманова” основана Станиславом Ашмановым и Игорем Модяевым в 2015 году.<br />Мы:<br /><br />– Разрабатываем и продаём продукты на нейронных сетях<br />– Развиваем первую российскую библиотеку для нейронных сетей<br />– Консультируем и помогаем бизнесам понять, как получить пользу от технологий машинного обучения<br />– Ведём научные исследования в направлении обучения без учителя, капсул Джеффри Хинтона и трекинга объектов на видео<br /><br />С 2015 года мы являемся резидентами Сколково и получаем поддержку Фонда Сколково.',
    },
    contacts: {
      heading: 'Контакты',
      tel: '+7 495 995-58-72',
      telLink: '+74959955872',
      email: 'info@ashmanov.net',
      address: '121357, Москва, ул. Верейская, д. 29, стр. 134.',
      formHeading: 'Напишите нам',
      form: {
        nameLabel: 'Представьтесь, пожалуйста',
        namePlace: 'Иван Иванов',
        emailLabel: 'Email',
        emailPlace: 'example@example.com',
        tel: 'Телефон',
        textareaPlace: 'Введите ваше сообщение',
        policy: '',
        acceptPolicy: 'Согласен с условиями',
        submitBtn: 'Отправить',
        success: 'Спасибо',
      },
      infoLink: {
        workTitle: 'По вопросам, связанным с работой или стажировкой в компании, пишите на e-mail:',
        workEmail: 'hr@ashmanov.net',
        pressTitle: 'Контакты для прессы:',
        pressEmail: 'pr@ashmanov.net',
        workingConditionsLink: 'Перечень рекомендуемых мероприятий по улучшений условий труда',
        statementOfLaborLink: 'Сводная ведомость результатов проведения специальной оценки условий труда'
      }
    },
    demos: {
      heading: 'Демо',
      subheading: 'Вы можете посмотреть примеры наших продуктов и технологий на нейронных сетях прямо в вашем браузере',
      cards: [
        {
          label: 'Детектор объектов',
          text: 'Нейросети для классификации фотографий.',
          href: '/tagger',
        },
        {
          label: 'Детектор очков',
          text: 'Нейросети для анализа предметов.',
          href: '/glasses/',
        },
        {
          label: 'Детектор локаций',
          text: 'Нейросети для определения типа локации по фото.',
          href: '/places/',
        },
        {
          label: 'Стилизатор',
          text: 'Технология Style Transfer позволяет создавать произведения искусства из обычных фотографий.',
          href: '/stylist/',
        },
        {
          label: 'Детектор NSFW-контента',
          text: 'Находим порно и эротические снимки с помощью нейросетей.',
          href: '/nsfw/',
        },
        {
          label: 'Детектор NSFW-контента',
          text: 'Находим порно и эротические снимки с помощью нейросетей.',
          href: '/#nsfw-content',
        },
      ],
    },
    libs: {
      heading: 'Библиотека PuzzleLib',
      text:
        'Нейросетевая библиотека PuzzleLib используется для конструирования, обучения и эксплуатации нейронных сетей. Библиотека поддерживает все современные виды нейронных сетей и использует для работы графические ускорители GPU. Библиотека работает на большинстве операционных систем: Windows, Linux, macOS, iOS.',
      btnText: 'Подробнее',
    },
    products: {
      heading: 'Продукты',
      cards: [
        {
          label: 'Детекторы объектов на фото и видео',
          text:
            'Мы разрабатываем алгоритмы выделения и классификации объектов на фото и видео. Детекторы работают со скоростью порядка 100 кадров в секунду на видеопотоке 640х480. Точность и полнота зависят от решаемой задачи и от требуемой скорости работы.',
          href: '/contacts/',
          btn: 'Подробнее',
        },
        {
          label: 'Видеоаналитика',
          text: 'Мы разработали технологию классификации текстов на естественном языке при помощи нейронных сетей.',
          href: '/contacts/',
          btn: 'Посмотреть',
        },
        {
          label: 'Библиотека PuzzleLib',
          text:
            'Нейросетевая библиотека PuzzleLib используется для конструирования, обучения и эксплуатации нейронных сетей. Библиотека поддерживает все современные виды нейронных сетей и использует для работы графические ускорители GPU.',
          href: '/puzzlelib/',
          btn: 'Узнать',
        },
      ],
    },
    reviews: {
      cards: [
        {
          img: 'IMG',
          name: 'Анатолий Соловьев',
          position: 'Исполнительный директор',
          company: 'Нефтянка',
          text:
            'Значимость этих проблем настолько очевидна, что консультация с широким активом требуют определения и уточнения систем массового участия. Товарищи! дальнейшее развитие различных форм деятельности позволяет выполнять важные задания по разработке систем массового участия. Не следует, однако забывать, что сложившаяся структура организации обеспечивает широкому кругу (специалистов) участие',
          href: '/',
          linkText: 'Читать далее',
        },
        {
          img: 'IMG',
          name: 'Анатолий Соловьев',
          position: 'Исполнительный директор',
          company: 'Нефтянка',
          text:
            'Значимость этих проблем настолько очевидна, что консультация с широким активом требуют определения и уточнения систем массового участия. Товарищи! дальнейшее развитие различных форм деятельности позволяет выполнять важные задания по разработке систем массового участия. Не следует, однако забывать, что сложившаяся структура организации обеспечивает широкому кругу (специалистов) участие',
          href: '/',
          linkText: 'Читать далее',
        },
        {
          img: 'IMG',
          name: 'Анатолий Соловьев',
          position: 'Исполнительный директор',
          company: 'Нефтянка',
          text:
            'Значимость этих проблем настолько очевидна, что консультация с широким активом требуют определения и уточнения систем массового участия. Товарищи! дальнейшее развитие различных форм деятельности позволяет выполнять важные задания по разработке систем массового участия. Не следует, однако забывать, что сложившаяся структура организации обеспечивает широкому кругу (специалистов) участие',
          href: '/',
          linkText: 'Читать далее',
        },
      ],
    },
  },
  productsPage: {
    title: 'Продукты - Нейросети Ашманова',
    btnMore: 'Подробнее',
    products: {
      heading: 'Продукты',
      cards: [
        {
          label: 'Библиотека PuzzleLib',
          text:
            'Нейросетевая библиотека PuzzleLib используется для конструирования, обучения и эксплуатации нейронных сетей. Библиотека поддерживает все современные виды нейронных сетей.',
        },
        {
          label: 'Анализ текстов',
          text: 'Мы разработали технологию классификации текстов на естественном языке при помощи нейронных сетей.',
        },
        {
          label: 'Детекторы объектов',
          text:
            'Мы разрабатываем алгоритмы выделения и классификации объектов на фото и видео. Детекторы работают со скоростью порядка 100 кадров в секунду на видеопотоке 640x480.',
        },
        {
          label: 'Нейросети на Jetson',
          text:
            'Наша библиотека может работать на встраиваемом компьютере NVIDIA Jetson TX2 или Xavier. Это позволяет запускать нейронные сети в роботах, автомобилях и беспилотниках.',
        },
        {
          label: 'Нейросети на iPhone',
          text:
            'Наша библиотека PuzzleLib поддерживает работу нейронных сетей на устройствах Apple (от iPhone 5s и новее).',
        },
        {
          label: 'Нейросети на Android',
          text:
            'PuzzleLib Mobile можно использовать для построения приложений с дополненной реальностью, а также приложений для распознавания данных на фото и видео.',
        },
        /* {
          label: 'Face Radar',
          text:
            'Система распознавания лиц Face Radar позволяет осуществлять автоматическую идентификацию лиц, половозрастных признаков и эмоций по видеоизображению.',
        }, */
      ],
    },
  },
  puzzleLibPage: {
    title: 'PuzzleLib - Нейросети Ашманова',
    heading: 'Библиотека PuzzleLib',
    btnMore: 'Подробнее',
    descrBlock: {
      heading: 'Что такое PuzzleLib',
      text:
        'PuzzleLib – это нейросетевая библиотека, т. е. ПО, которое позволяет собрать нейронную сеть под конкретную задачу, визуализировать её архитектуру, запустить обучение этой нейронной сети и выгрузить обученную сеть для использования. <br /> <br /> Аналоги: иностранные библиотеки TensorFlow, Torch, CNTK, разработанные компаниями Google, Facebook, Microsoft.',
    },
    reqBlock: {
      heading: 'Что для библиотеки важно?',
      list: [
        'Алгоритмы: иметь поддержку классических нейросетевых алгоритмов, которые в рамках библиотеки реализуются в виде отдельных модулей вычислений.',
        'Поддержка CPU и GPU: обучение нейронной сети выгоднее проводить на GPU, но поддержка CPU может потребоваться для случаев, когда нет возможности использовать дорогостоящие GPU.',
        'Кроссплатформенность: нужна возможность запускать нейросети на различных операционных системах и процессорных архитектурах, в частности – на мобильных устройствах.',
        'Скорость: в задачах с большими данными обучение нейросети может занимать дни. Ускорение в 1.5-2 раза – уже существенно для рабочего процесса. Кроме того, быстрая обработка данных важна в задачах, где нужна работа в реальном времени (например, анализ видео на борту).',
      ],
    },
    advantageBlock: {
      heading: 'Преимущества PuzzleLib',
      list: [
        'Высокая скорость: мы используем только низкоуровневые утилиты для видеокарт NVIDIA и AMD и эффективно задействуем ресурсы процессора.',
        'Поддерживаем работу на CPU и GPU (центральные процессоры и видеокарты).',
        'Библиотека может работать на всех основных операционных системах (семейство Linux, Windows, Mac OS, iOS и Android).',
        'Автоматическая оптимизация вычислений. В некоторых случаях это ускоряет обучение нейросети в 2 раза.',
        '<b>Более 60 видов нейросетевых модулей</b>, которые позволяют строить свёрточные, рекуррентные нейросети и сети с произвольными графами вычислений. Список модулей – в соответствующем разделе ниже.',
        '<b>Порог входа</b> в PuzzleLib для пользователя гораздо ниже, чем для других библиотек.',
        'PuzzleLib – полностью отечественная разработка.',
      ],
    },
    modulesBlock: {
      heading: 'Список модулей, реализованных в PuzzleLib',
      descr:
        'Любая нейросеть представляет из себя граф вычислений: каждый элемент графа – это модуль нашей библиотеки, производящий операции над переданными ему данными. Ниже приводится список модулей, которые сейчас есть в PuzzleLib. Они позволяют собрать любую современную нейронную архитектуру.',
      modules: [
        {
          name: 'Базовые модули:',
          list: [
            'Activation (Sigmoid, Tanh, ReLU, ELU, LeakyReLU, SoftMaxPlus)',
            'AvgPool (1D, 2D, 3D)',
            'BatchNorm (1D, 2D, 3D, ND)',
            'Conv (1D, 2D, 3D, ND)',
            'CrossMapLRN',
            'Deconv (1D, 2D, 3D, ND)',
            'Dropout (1D, 2D)',
            'GroupLinear',
            'InstanceNorm2D',
            'KMaxPool',
            'LCN',
            'Linear',
            'LRN',
            'MapLRN',
            'MaxPool (1D, 2D, 3D)',
            'MaxUnpool2D',
            'Pool (1D, 2D, 3D)',
            'RNN',
            'SoftMax',
            'SubtractNorm',
            'Upsample (2D, 3D)',
          ],
        },
        {
          name: 'Специальные модули:',
          list: ['Embedder', 'SpatialTf'],
        },
        {
          name: 'Вспомогательные модули:',
          list: [
            'Add',
            'Concat',
            'DepthConcat',
            'Flatten',
            'Glue',
            'Identity',
            'MoveAxis',
            'Mul',
            'MulAddConst',
            'NoiseInjector',
            'Penalty',
            'Replicate',
            'Reshape',
            'Split',
            'Sum',
            'SwapAxes',
            'Tile',
            'ToList',
            'Transpose',
          ],
        },
      ],
    },
    algBlock: {
      heading: 'Список алгоритмов оптимизации, реализованных в PuzzleLib',
      descr:
        'Для обучения нейронной сети необходимо итерационно обновлять её веса с помощью алгоритма, называемого «оптимизатором». Для разных задач (например, регрессия, классификация текстов или изображений) нужны разные оптимизаторы.',
      list: [
        'AdaDelta',
        'AdaGrad',
        'Adam',
        'Hooks',
        'LBFGS',
        'MomentumSGD',
        'NesterovSGD',
        'RMSProp',
        'RMSPropGraves',
        'SGD',
        'SMORMS3',
      ],
    },
  },
  consultingPage: {
    title: 'Консалтинг - Нейросети Ашманова',
    btnMore: 'Подробнее',
    heading: 'Консалтинг',
    consultingText: {
      heading: 'Наши услуги',
      contactBtn: 'Получить пользу',
      list: [
        {
          label: 'Консультируем, как в вашем бизнесе получить пользу от ИИ',
          icon: { class: 'fas', name: 'hands-helping' },
        },
        {
          label: 'Помогаем проработать задачу',
          icon: { class: 'fas', name: 'project-diagram' },
        },
        {
          label: 'Пишем техническое задание',
          icon: { class: 'fas', name: 'file-signature' },
        },
        {
          label: 'Обучаем команду ваших программистов',
          icon: { class: 'fas', name: 'chalkboard-teacher' },
        },
      ],
    },
    consultingCards: {
      heading: 'По отраслям',
      contactBtn: 'Оптимизировать бизнес',
      cards: [
        {
          label: 'Консалтинг PuzzleLib',
          text:
            'Нейросетевая библиотека PuzzleLib используется для конструирования, обучения и эксплуатации нейронных сетей. Библиотека поддерживает все современные виды нейронных сетей.',
        },
        {
          label: 'Консалтинг PuzzleLib',
          text:
            'Нейросетевая библиотека PuzzleLib используется для конструирования, обучения и эксплуатации нейронных сетей. Библиотека поддерживает все современные виды нейронных сетей.',
        },
        {
          label: 'Консалтинг PuzzleLib',
          text:
            'Нейросетевая библиотека PuzzleLib используется для конструирования, обучения и эксплуатации нейронных сетей. Библиотека поддерживает все современные виды нейронных сетей.',
        },
        {
          label: 'Консалтинг PuzzleLib',
          text:
            'Нейросетевая библиотека PuzzleLib используется для конструирования, обучения и эксплуатации нейронных сетей. Библиотека поддерживает все современные виды нейронных сетей.',
        },
        {
          label: 'Консалтинг PuzzleLib',
          text:
            'Нейросетевая библиотека PuzzleLib используется для конструирования, обучения и эксплуатации нейронных сетей. Библиотека поддерживает все современные виды нейронных сетей.',
        },
        {
          label: 'Консалтинг PuzzleLib',
          text:
            'Нейросетевая библиотека PuzzleLib используется для конструирования, обучения и эксплуатации нейронных сетей. Библиотека поддерживает все современные виды нейронных сетей.',
        },
      ],
    },
  },
  demoPage: {
    title: 'Демо - Нейросети Ашманова',
    heading: 'Демо',
    btnBack: 'Назад',
    uploadText: 'Перетащите файл сюда',
    demos: {
      heading: 'Демо',
      subheading: 'Вы можете посмотреть примеры наших продуктов и технологий на нейронных сетях прямо в вашем браузере',
      cards: [
        {
          label: 'Детектор объектов',
          text: 'Нейросети для классификации фотографий.',
          href: '/tagger',
        },
        {
          label: 'Детектор очков',
          text: 'Нейросети для анализа сэлфи.',
          href: '/glasses/',
        },
        {
          label: 'Детектор локаций',
          text: 'Нейросети для определения типа локации по фото.',
          href: '/places/',
        },
        {
          label: 'Стилизатор',
          text: 'Технология Style Transfer позволяет создавать произведения искусства из обычных фотографий.',
          href: '/stylist/',
        },
        {
          label: 'Детектор NSFW-контента',
          text: 'Находим порно и эротические снимки с помощью нейросетей.',
          href: '/nsfw/',
        },
        {
          label: 'Детектор NSFW-контента',
          text: 'Находим порно и эротические снимки с помощью нейросетей.',
          href: '/#nsfw-content',
        },
      ],
    },
    demoTagger: {
      heading: 'Детектор объектов',
      description: `<p>Детектор реализован на базе глубокой свёрточной сети. Нейронную сеть обучили распознавать несколько сотен видов объектов из разных областей.</p>
      <p>Использовалась модификация глубокой архитектуры ResNet, фреймворк нейронных сетей - PuzzleLib. Детектор работает на видеокартах NVIDIA GTX 1080 и Tesla K80.</p>
      <p>Попробуйте загрузить фотографию, после чего детектор выдаст результат распознавания.</p>`,
    },
    demoGlasses: {
      heading: 'Детектор очков',
      description: `<p>Применение нейросетей для анализа изображений с людьми. Можно использовать для таргетирования рекламы в социальных сетях. Предоставляем в виде онлайн-сервиса, есть API.</p>
      <p>Использовалась модификация глубокой архитектуры ResNet, фреймворк нейронных сетей - PuzzleLib. Детектор работает на видеокартах NVIDIA GTX 1080 и Tesla K80.</p>
      <p>Попробуйте загрузить фотографию, после чего детектор выдаст результат распознавания.</p>`,
    },
    demoPlaces: {
      heading: 'Детектор локаций',
      description: `<p>Детектор реализован на базе глубокой свёрточной сети. Нейронная сеть распознаёт несколько сотен типов локаций (рестораны, бары, музеи и пр.)</p>
      <p>Мы используем модификацию глубокой архитектуры ResNet, фреймворк нейронных сетей - PuzzleLib. Работает на видеокарте NVIDIA GTX 1080 и Tesla K80.</p>
      <p>Попробуйте загрузить фотографию, после чего детектор выдаст результат распознавания.</p>`,
    },
    demoStylist: {
      heading: 'Стилизатор',
      description: `<p>Стилизация изображения происходит с помощью технологии Style Transfer. Обработка делается за один проход фото через нейронную сеть - на два порядка быстрее итерационных методов.</p>
      <p>Эта технология - демонстрация того, что нейронные сети можно применять не только для анализа или классификации данных. Стилизация изображения - хороший пример использования нейросетей для развлечения, искусства и дизайна.</p>`,
    },
    demoNSFW: {
      heading: 'Детектор NSFW контента',
      description: `<p>Детектор реализован на базе глубокой свёрточной сети. Нейронную сеть обучили распознавать, насколько вероятно, что предоставленный контент принадлежит категории 'Материалы для взрослых'.</p>
      <p>Использовалась модификация глубокой архитектуры ResNet, фреймворк нейронных сетей - PuzzleLib. Детектор работает на видеокартах NVIDIA GTX 1080 и Tesla K80.</p>
      <p>Попробуйте загрузить фотографию, после чего детектор выдаст результат распознавания.</p>`,
    },
  },
  footer: {
    heading: 'Контакты',
    tel: '+7 495 995-58-72',
    telLink: '+74959955872',
    email: 'info@ashmanov.net',
    address: '121357, Москва, ул. Верейская, д.29, стр. 134.',
    copyright: 'Нейросети Ашманова 2015',
  },
}
