module.exports = {
  language: 'EN',
  logo: {
    text: 'Ashmanov <br/>Neural Networks',
  },
  nav: [
    { title: 'Home', href: '/' },
    { title: 'Products', href: '/products/' },
    { title: 'Consulting', href: '/consulting/' },
    /*{ title: 'Demo', href: '/demos/' },*/
    { title: 'PuzzleLib', href: '/puzzlelib/' },
    { title: 'Our Partners', href: '/partners/' },
    { title: 'Contact Us', href: '/contacts/' },
  ],
  vacanciesLink: '',
  contactBtn: 'Contact us',
  frontpage: {
    title: 'Ashmanov Neural Networks – artificial intelligence and machine learning technologies',
    meta: {
      description: 'We develop products based on neural networks and Machine Learning. We provide Machine Learning technology consulting services.',
    },
    banner: {
      h1: ' Neural network-based apps and services',
      config: [
        {
          text:
            'We develop products based on neural networks <br />and Machine Learning.<br /> We provide Machine Learning technology consulting services.',
          btn: 'Read more',
          href: '/consulting/',
        },
        {
          text:
            'Would you like to learn how you can benefit from employing machine learning solutions? We are happy to offer you a first consultation session for free.',
          btn: 'Submit request',
          href: '/#contact',
        },
        {
          text: 'PuzzleLib framework: we have developed <br />the most fast-speed neural network building framework.',
          btn: 'Read more',
          href: '/puzzlelib/',
        },
      ],
    },
    about: {
      heading: 'About Us',
      text: `“Ashmanov Neural Networks” company was founded in 2015 by Stanislav Ashmanov and Igor Modyaev. <br />What we do:<br /><br />–We develop and sell neural network-based products <br />–We have created and are advancing Russia’s first deep learning framework <br />–We advise businesses and help our clients identify how to benefit from introducing machine learning technologies <br />– We conduct research on unsupervised learning, on Geoffrey Hinton’s capsule networks, and on object tracking in video <br /><br />Starting in 2015, we have been a Skolkovo resident and we have been receiving Skolkovo Foundation support.`,
    },
    partners: {
      heading: 'Our partners',
    },
    contacts: {
      heading: 'Contacts',
      tel: '+7 495 995-58-72',
      telLink: '+74959955872',
      email: 'info@ashmanov.net',
      address: 'Vereyskaya st., 29/134, Moscow 121357.',
      formHeading: 'Contact us',
      form: {
        nameLabel: 'Please introduce yourself',
        namePlace: 'John Doe',
        emailLabel: 'Email',
        emailPlace: 'Your email',
        tel: 'Phone',
        textareaPlace: 'Please type your message here',
        policy: '',
        acceptPolicy: 'I agree to the Terms and Conditions',
        submitBtn: 'Submit',
        success: 'Thank you',
      },
      infoLink: {
        workTitle: 'If you are interested in a job or internship in the company, please contact us by email:',
        workEmail: 'hr@ashmanov.net',
        pressTitle: 'For media requests:',
        pressEmail: 'pr@ashmanov.net',
        workingConditionsLink: 'List of recommended measures to improve working conditions',
        statementOfLaborLink: 'Summary statement of the results of a special assessment of working conditions'
      }
    },
    demos: {
      heading: 'Demo',
      subheading: 'You can view our sample neural network-based products and technologies right in your browser',
      cards: [
        /*{
          label: 'Object detection',
          text: 'Using neural networks for image classification.',
          href: '/tagger',
        },*/
        {
          label: 'Glasses detection',
          text: 'Using neural networks for analyzing selfies.',
          href: '/glasses/',
        },
        {
          label: 'Location detection',
          text: 'Using neural networks to identify location type from a photo.',
          href: '/places/',
        },
        {
          label: 'Stylization',
          text: 'Style Transfer technology allows to create artsy masterpieces from regular photos.',
          href: '/stylist/',
        },
        {
          label: 'NSFW-content detection',
          text: 'Using neural networks to detect unsafe content: nudity and pornography.',
          href: '/nsfw/',
        },
        {
          label: 'Ashmanov <br/>Neural Networks',
          text: 'Contact us.',
          href: '/#contact',
        },
      ],
    },
    libs: {
      heading: 'PuzzleLib Framework',
      text: `PuzzleLib neural network framework is designed for building, training, and operating neural networks. The framework supports all cutting-edge types of neural networks and utilizes GPU accelerators. The library is available through the majority of operating systems: Windows, Linux, MacOS, iOS.`,
      btnText: 'Read more',
    },
    products: {
      heading: 'Products',
      cards: [
        {
          label: 'Object detection for photo and video',
          text: `We develop algorithms for detecting and classifying objects in photos and videos. Our detectors perform at an average speed of 100 frames per second for a 640х480 video stream. Detection accuracy and exhaustiveness depend on the specific task and the required detection speed.`,
          href: '/contacts/',
          btn: 'Read more',
        },
        /* {
          label: 'Text Analysis',
          text: `We have developed a solution for classifying natural language text with the help of neural networks.`,
          href: '/contacts/',
          btn: 'Read more',
        }, */
        {
          label: 'Face Radar',
          text: 'The Face Radar face recognition system enables automatic identification of faces, age and gender characteristics and emotions from the video image.',
          href: '/contacts/',
          btn: 'Read more',
        },
        {
          label: 'PuzzleLib Framework',
          text: `PuzzleLib neural network framework is designed for building, training, and operating neural networks. The framework supports all cutting-edge types of neural networks and utilizes GPU accelerators.`,
          href: '/puzzlelib/',
          btn: 'Read more',
        },
      ],
    },
    reviews: {
      cards: [
        {
          img: 'IMG',
          name: 'Анатолий Соловьев',
          position: 'Исполнительный директор',
          company: 'Нефтянка',
          text:
            'Значимость этих проблем настолько очевидна, что консультация с широким активом требуют определения и уточнения систем массового участия. Товарищи! дальнейшее развитие различных форм деятельности позволяет выполнять важные задания по разработке систем массового участия. Не следует, однако забывать, что сложившаяся структура организации обеспечивает широкому кругу (специалистов) участие',
          href: '/',
          linkText: 'Читать далее',
        },
        {
          img: 'IMG',
          name: 'Анатолий Соловьев',
          position: 'Исполнительный директор',
          company: 'Нефтянка',
          text:
            'Значимость этих проблем настолько очевидна, что консультация с широким активом требуют определения и уточнения систем массового участия. Товарищи! дальнейшее развитие различных форм деятельности позволяет выполнять важные задания по разработке систем массового участия. Не следует, однако забывать, что сложившаяся структура организации обеспечивает широкому кругу (специалистов) участие',
          href: '/',
          linkText: 'Читать далее',
        },
        {
          img: 'IMG',
          name: 'Анатолий Соловьев',
          position: 'Исполнительный директор',
          company: 'Нефтянка',
          text:
            'Значимость этих проблем настолько очевидна, что консультация с широким активом требуют определения и уточнения систем массового участия. Товарищи! дальнейшее развитие различных форм деятельности позволяет выполнять важные задания по разработке систем массового участия. Не следует, однако забывать, что сложившаяся структура организации обеспечивает широкому кругу (специалистов) участие',
          href: '/',
          linkText: 'Читать далее',
        },
      ],
    },
  },
  productsPage: {
    title: 'Products – Ashmanov Neural Networks',
    btnMore: 'Read more',
    products: {
      heading: 'Products',
      cards: [
        {
          label: 'PuzzleLib Framework',
          text:
            'PuzzleLib neural network framework is designed for building, training, and operating neural networks. The framework supports all cutting-edge types of neural network.',
        },
        {
          label: 'Text Analysis',
          text: 'We have developed a solution for classifying natural language text with the help of neural networks.',
        },
        {
          label: 'Object detection',
          text:
            'We develop algorithms for detecting and classifying objects in photos and videos. Our detectors perform at an average speed of 100 frames per second for a 640х480 video stream.',
        },
        {
          label: 'Neural networks built on Jetson',
          text:
            'Our deep learning framework can operate on the Jetson TX2 or Xavier embedded computers. This allows us to launch neural network solutions in robots, cars, and drones.',
        },
        {
          label: 'Neural networks for iPhone',
          text:
            'Our PuzzleLib deep learning framework supports neural network integration for Apple devices (starting from iPhone 5s and up).',
        },
        {
          label: 'Neural networks for Android',
          text:
            'You can use PuzzleLib Mobile for building apps with augmented reality and apps for data recognition in photos and videos.',
        },
      ],
    },
  },
  puzzleLibPage: {
    title: 'PuzzleLib: Ashmanov Neural Networks',
    heading: 'PuzzleLib Deep Learning Framework',
    btnMore: 'Read more',
    descrBlock: {
      heading: 'What is PuzzleLib?',
      text: `PuzzleLib is a neural network framework or a software that allows to build a neural network for a specific task, to visualize its architecture, launch the training of the network, and export the trained neural network for further implementation. <br /> <br /> Some of the analogs include international frameworks TensorFlow, Torch, CNTK, developed by Google, Facebook, Microsoft.`,
    },
    reqBlock: {
      heading: 'What is important in a deep learning framework?',
      list: [
        'Algorithms: it must support the classic neural network algorithms that are executed as separate computing modules inside the framework.',
        'CPU and GPU support: it is more beneficial to train neural networks on GPU, yet CPU support may be essential when there is no opportunity to rely on the expensive GPU.',
        'Cross-platform nature: it is important to have the opportunity to launch neural networks from various operating systems and processor architecture designs, including the mobile devices.',
        'Speed: tasks geared toward big data neural network training may take up several days. Increasing the speed by 1.5-2 times is a significant improvement for the working process. What’s more, fast data processing is essential in tasks performed in real time, such as on-board video analysis.',
      ],
    },
    advantageBlock: {
      heading: 'PuzzleLib Strengths',
      list: [
        'High speed: we employ exclusively low-level utility software for NVIDIA and AMD graphic cards and efficiently leverage on the processor resources.',
        'CPU and GPU support: cornerstone processors and graphic cards.',
        'The framework runs on all key operating systems: Linux family, Windows, MacOS, iOS, and Android.',
        'Automated computing optimization: in some cases it accelerates the speed of neural network training twice.',
        '<b>Over 60 types of available neural network modules</b> that allow to build convolutional and recurrent neural networks and networks with arbitrary graph structure. Below you can find the list of modules.',
        '<b>Entry barrier</b> for PuzzleLib users is significantly lower than in any other frameworks.',
        'PuzzleLib is a Russia’s domestically designed solution.',
      ],
    },
    modulesBlock: {
      heading: 'List of modules implemented in PuzzleLib',
      descr:
        'Any neural network is a computational graph where each element is a module in our framework that can execute operations with the received data. Below is the full list of modules contained by PuzzleLib. They allow you to design any possible cutting-edge neural architecture.',
      modules: [
        {
          name: 'Basic modules:',
          list: [
            'Activation (Sigmoid, Tanh, ReLU, ELU, LeakyReLU, SoftMaxPlus)',
            'AvgPool (1D, 2D, 3D)',
            'BatchNorm (1D, 2D, 3D, ND)',
            'Conv (1D, 2D, 3D, ND)',
            'CrossMapLRN',
            'Deconv (1D, 2D, 3D, ND)',
            'Dropout (1D, 2D)',
            'GroupLinear',
            'InstanceNorm2D',
            'KMaxPool',
            'LCN',
            'Linear',
            'LRN',
            'MapLRN',
            'MaxPool (1D, 2D, 3D)',
            'MaxUnpool2D',
            'Pool (1D, 2D, 3D)',
            'RNN',
            'SoftMax',
            'SubtractNorm',
            'Upsample (2D, 3D)',
          ],
        },
        {
          name: 'Special modules:',
          list: ['Embedder', 'SpatialTf'],
        },
        {
          name: 'Additional modules:',
          list: [
            'Add',
            'Concat',
            'DepthConcat',
            'Flatten',
            'Glue',
            'Identity',
            'MoveAxis',
            'Mul',
            'MulAddConst',
            'NoiseInjector',
            'Penalty',
            'Replicate',
            'Reshape',
            'Split',
            'Sum',
            'SwapAxes',
            'Tile',
            'ToList',
            'Transpose',
          ],
        },
      ],
    },
    algBlock: {
      heading: 'List of optimizing algorithms executed in PuzzleLib',
      descr:
        'For neural network training it is essential to iterationally update its weights with an algorithm known as an “optimizer”. You need different optimizers for different tasks, for example, for regression or for text or image classification.',
      list: [
        'AdaDelta',
        'AdaGrad',
        'Adam',
        'Hooks',
        'LBFGS',
        'MomentumSGD',
        'NesterovSGD',
        'RMSProp',
        'RMSPropGraves',
        'SGD',
        'SMORMS3',
      ],
    },
    documentsBlock: {
      heading: 'Documents',
      documents: [
        /*{ title: 'PuzzleLib description', id: 'Описание PuzzleLib' },*/
        { title: 'PuzzleLib setup instructions', id: 'Инструкция по установке нейросетевой библиотеки PuzzleLib' },
        { title: 'Software lifecycle', id: 'СК ДП Сопровождение ПО Нейросети Ашманова Жизненный цикл ПО' },
        { title: 'PuzzleLib licence', id: 'Лицензия PuzzleLib' },
        { title: 'PuzzleLib price list', id: 'Прайс-лист PuzzleLib' },
      ],
    },
  },
  faceRadarPage: {
    title: 'FaceRadar - Ashmanov Neural Networks',
  },
  consultingPage: {
    title: 'Consulting - Ashmanov Neural Networks',
    btnMore: 'Подробнее',
    heading: 'Consulting',
    consultingText: {
      heading: 'Our Services',
      contactBtn: 'Get',
      list: [
        {
          label: 'Consult on how your business can benefit from AI',
          icon: { class: 'fas', name: 'hands-helping' },
        },
        {
          label: 'Help to elaborate the task',
          icon: { class: 'fas', name: 'project-diagram' },
        },
        {
          label: 'Write technical requirements',
          icon: { class: 'fas', name: 'file-signature' },
        },
        {
          label: 'Train your team of programmers',
          icon: { class: 'fas', name: 'chalkboard-teacher' },
        },
      ],
    },
    consultingCards: {
      heading: 'По отраслям',
      cards: [
        {
          label: 'Консалтинг PuzzleLib',
          text:
            'Нейросетевая библиотека PuzzleLib используется для конструирования, обучения и эксплуатации нейронных сетей. Библиотека поддерживает все современные виды нейронных сетей.',
        },
      ],
    },
  },
  demoPage: {
    title: 'Demo - Ashmanov Neural Networks',
    heading: 'Demo',
    btnBack: 'Back',
    uploadText: 'Upload an image',
    samplesHeading: 'Choose your style',
    demos: {
      heading: 'Demo',
      subheading: 'Have a look at some examples of neural netwoks right in your browser.',
      cards: [
        /*{
          label: 'Object Detector',
          text: 'Neural networks for photo classification',
          href: '/tagger',
        },*/
        {
          label: 'Glasses Detector',
          text: 'Neural networks for selfie analysis',
          href: '/glasses/',
        },
        {
          label: 'Location Detector',
          text: 'Neural networks for recognition of locations',
          href: '/places/',
        },
        {
          label: 'Stylizator',
          text: 'Style Transfer technology for fun, art and design.',
          href: '/stylist/',
        },
        {
          label: 'NSFW Content Detector',
          text: 'Recognition of the adult content.',
          href: '/nsfw/',
        },
        {
          label: 'Face Recognition ',
          text: 'Coming soon!',
          href: '/#',
        },
      ],
    },
    /*demoTagger: {
      heading: 'Object Detector',
      description: `<p>Detector is based on deep convolutional neural network. Trained to recognize hundreds of diverse objects.</p>
      <p>ResNet-like architecture, PuzzleLib Deep Learning framework. Works on NVIDIA GTX 1080 card.</p>
      <p>Try to upload a photo to see the result of recognition.</p>`,
    },*/
    demoGlasses: {
      heading: 'Glasses Detector',
      description: `<p>Usage of deep neural networks for people photos analysis. Can be useful for ads targeting in social networks. Can be accessed via HTTP API.</p>
      <p>ResNet-like architecture, PuzzleLib Deep Learning framework. Works on NVIDIA GTX 1080 card.</p>
      <p>Try to upload a photo to see the result of recognition.</p>`,
    },
    demoPlaces: {
      heading: 'Location Detector',
      description: `<p>Detector is based on deep convolutional neural network. Trained to recognize hundreds of locations (bars, restaurants, museum etc.).</p>
      <p>ResNet-like architecture, PuzzleLib Deep Learning framework. Works on NVIDIA GTX 1080 card.</p>
      <pTry to upload a photo to see the result of recognition.</p>`,
    },
    demoStylist: {
      heading: 'Stylizator',
      downloadBtn: 'Download',
      description: `<p>Stylization is perfomed with Style Transfer technology. Processing is done in a single inference step, x100 times faster than in iterational methods.</p>
      <p>The technology demonstrates that neural networks can be used not only for data analysis or classification. Image stylization is a great example of Deep Learning usage for fun, art and design.</p>`,
    },
    demoNSFW: {
      heading: 'NSFW Content Detector',
      description: `<p>Detector is based on deep convolutional neural network. The network was trained to estimate the probability that the photo provided can contain some adult content.</p>
      <p>ResNet-like architecture, PuzzleLib Deep Learning framework. Works on NVIDIA GTX 1080 card.</p>
      <p>Try to upload a photo to see the result of recognition.</p>`,
    },
  },
  partnersPage: {
    title: 'Our partners - Ashmanov Neural Networks',
    heading: 'Our partners',
    partnersBlock: [
      {
        imgName: 'fasie',
        imgLink: 'http://fasie.ru/',
        name: 'Foundation for Assistance to Small Innovative Enterprises (FASIE)',
        description: `Our company was supported by the <a href="http://fasie.ru/" target="_blank">Foundation for Assistance to Small Innovative Enterprises (FASIE)</a>. In 2015, we participated in the program "START-1" with the project "Puzzle: image and video recognition system based on neural networks". <br><br>
In 2018, we participated in the program "Start-2" with a project "Conducting research in the field of implementing a software for image recognition in IT companies' products. Research and development of video analytics technology. Research in multiple operating systems' support, including mobile operating systems."`
      },
      {
        imgName: 'nvidia',
        imgLink: 'https://www.nvidia.com/en-us/about-nvidia/',
        name: 'NVIDIA',
        description: `Nvidia are our partners in machine learning. We often use Nvidia graphic cards and software in of our projects, as well as receive technical support in the matter. <br><br>
<a href="https://www.nvidia.com/en-us/about-nvidia/" target="_blank">Nvidia</a> company is the leader in artificial intelligence, and its products are the most popular solution for commercial machine learning. `
      },
      {
        imgName: 'intel',
        imgLink: 'https://www.intel.com/content/www/us/en/homepage.html',
        name: 'Intel',
        description: `We cooperate with Intel in the machine learning field. <br><br>
<a href="https://www.intel.com/content/www/us/en/homepage.html" target="_blank">Intel</a> is one of the largest producers of electronics and computer hardware, including microprocessor. At this time, the company has a vast selection of AI technology under its belt, from with data processing centers to adj-computing systems and peripherals. Intel Corporation offers hardware and software solutions for all AI fields including machine and deep learning. We occasionally use Intel products for neural network computations. For instance, <a href="https://habr.com/ru/company/ashmanov_net/blog/346958/" target="_blank">here</a> we describe our experience with Intel Movidius neural chip in detail. `
      },
      {
        imgName: 'mcst',
        imgLink: 'http://www.mcst.ru/',
        name: 'MCST',
        description: `<a href="http://www.mcst.ru/" target="_blank">JSC MCST</a> designs unique high-performance microprocessors and computational facilities. <a href="http://elbrus.ru/" target="_blank">Elbrus</a> microprocessor is one of them”. We have exported <a href="https://ashmanov.net/en/puzzlelib" target="_blank">PuzzleLib</a> neural network library to Elbrus for operation of neural network-based face recognition system.`
      },
      {
        imgName: 'mfti',
        imgLink: 'https://mipt.ru/science/labs/',
        name: 'MIPT',
        description: `In autumn of 2018, Stanislav Ashmanov has opened a Laboratory of Neural Network Technologies and Applied Linguistics in MIPT. Laboratory’s staff conducts researches on deep learning and develop their own technology for speech recognition and synthesis. Soon, the laboratory will get its own page, where we will tell everything in detail.<br><br>
We are also a participant of <a href="https://mipt.ru/ai/" target="_blank">Artificial Intelligence Center</a> which was created as part of the Russian National Technical Initiative (NTI) program. Artificial Intelligence Center is a consortium, of which our company is a member of.`
      },
      // {
      //   imgName: 'mgu',
      //   name: 'Lomonosov Moscow State University (MSU)',
      //   description: ''
      // },
      // {
      //   imgName: 'itmo',
      //   name: 'ITMO University',
      //   description: ''
      // },
      {
        imgName: 'infowatch',
        imgLink: 'https://www.infowatch.ru/',
        name: 'Infowatch',
        description: `Our company is a partner of JSC InfoWatch group of companies. <br><br>
<a href="https://www.infowatch.ru/solutions" target="_blank">InfoWatch</a> group of companies is a developer of complex solutions in the field of organization data security. The company’s product portfolio includes effective solutions for protection from the latest threats to a company’s information security, both internal and external, as well as information attacks and solutions for industrial security.`
      },
      {
        imgName: 'fefu',
        imgLink: 'https://www.dvfu.ru/',
        name: 'Far Eastern Federal University (FEFU)',
        description: `We are a member of the NTI <a href="https://www.dvfu.ru/news/fefu-news/the_university_held_the_first_meeting_of_the_consortium_sti_for_neurotechnology_vr_and_ar/" target="_blank">consortium</a> on neural technologies in virtual and augmented realities (VR/AR), founded in 2017 on FEFU grounds. <br><br>
Consortium’s main strategy is the simultaneous development of software and hardware facilities that will expand the interaction and ecosystem integration capabilities. By 2021, it is planned to establish a competitive platform for VR/AR products development, as well as a professional community that will operate on the open source principle and will determine its own marketing standards.`
      },
      {
        imgName: 'nanosemantics',
        imgLink: 'https://nanosemantics.ai/?utm_source=ashmanov_net&utm_medium=parnter_page',
        name: 'Nanosemantics',
        description: `Our colleagues and next door neighbors, the leading chatbot developer in Russia and CIS countries, Nanosemantics Laboratories. <br><br>
<a href="https://nanosemantics.ai/en/our-projects/" target="_blank">Nanosemantics</a> develops smart chatbots that are able to communicate in a natural language, provide internal and external support to company’s both clients and employees in any field (banks, telecom, retail, services). <br><br>
As of late, our companies are a large and friendly team, working on neural network and machine learning-based chatbots together. Some of the staff are also members of the speech recognition and synthesis center. `
      },
      {
        imgName: 'progkids',
        imgLink: 'http://progkids.com/?utm_source=ashmanov',
        name: 'ProgKids',
        description: `We cooperate with <a href="http://progkids.com/?utm_source=ashmanov" target="_blank">ProgKids</a> - an online platform for learning the basics of programming. On the platform, you can learn either independently from ready-made lessons or with a teacher in the format of a video call. The lessons' baseline is constantly updated. Your child will be able to write code himself and see the result in the game Minecraft.`
      },
    ],
    form: {
      title: 'If you have cooperation offers or something interesting to tell - drop us a line and we will discuss it together!',
      nameLabel: 'Please introduce yourself',
      namePlace: 'John Doe',
      emailLabel: ' Your email',
      emailPlace: 'example@example.com',
      tel: 'Your phone number',
      textareaPlace: 'Please type your message here',
      submitBtn: 'Submit',
      success: 'Thank you!',
    },
  },
  blogPage: {
    title: 'Blog',
    moreBtn: 'More posts',
  },
  tagsPage: {
    title: 'Tags',
    allTags: 'All tags',
    postsWithTag: 'Posts with tag',
  },
  footer: {
    heading: 'Contacts',
    tel: '+7 495 995-58-72',
    telLink: '+74959955872',
    email: 'info@ashmanov.net',
    address: 'Russia, Moscow, Vereyskaya st., 29/134',
    copyright: '© Ashmanov Neural Networks 2015 ',
  },
}
